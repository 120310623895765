window.Vue = require("vue");
import { defineAsyncComponent, createApp, h } from "vue";

//import libraries
import "../css/bootstrap.min.css";
import "../css/grid-custom.css";
import "../libs/ico-moon/style.css";
import "../js/bootstrap.min";

//import initiator
import Initiator from "./initiator/index.vue";
//import postload operations
import PostLoad from "./postLoad/index.vue";
//create module load count. Used to determine how many modukles are loaded to remove loading screen.
let asyncModuleNumber = 0;
//Import navbars and footers
const NavBasicDesktop = defineAsyncComponent(() => import("./_navbars/desktop/basic/index.vue"));
const NavIndentDesktop = defineAsyncComponent(() => import("./_navbars/desktop/indent/index.vue"));
const NavBasicMobile = defineAsyncComponent(() => import("./_navbars/mobile/basic/index.vue"));
const NavMinimalDesktop = defineAsyncComponent(() => import("./_navbars/desktop/minimal/index.vue"));
const NavMinimalMobile = defineAsyncComponent(() => import("./_navbars/mobile/minimal/index.vue"));
//import components
const Text = defineAsyncComponent(() => import("./text/index.vue"));
const SurgaRte = defineAsyncComponent(() => import("./surgaRte/index.vue"));
const SurgaSecureRte = defineAsyncComponent(() => import("./surgaSecureRte/index.vue"));
const SortbarBasic = defineAsyncComponent(() => import("./_property/sortbar-retro/index.vue"));
const FeaturedProperty = defineAsyncComponent(() => import("./_property/featuredProperty/index.vue"));
const SearchBasic = defineAsyncComponent(() => import("./_property/searchbar/index.vue"));
const SearchBox = defineAsyncComponent(() => import("./_property/searchbarBox/index.vue"));
//import property components
const ListingResults = defineAsyncComponent(() => import("./_property/searchResult/general/index.vue"));
const WatchlistResults = defineAsyncComponent(() => import("./_property/watchlistResult/index.vue"));
const AgencyResults = defineAsyncComponent(() => import("./_property/agencyResults/index.vue"));
const AgentResults = defineAsyncComponent(() => import("./_property/searchResult/agent/index.vue"));
const AuctionResults = defineAsyncComponent(() => import("./_property/searchResult/auction/index.vue"));
//import image components
const ImageBasic = defineAsyncComponent(() => import("./_image/image-basic/index.vue"));
const ImageEnlarge = defineAsyncComponent(() => import("./_image/image-enlarge/index.vue"));
const ImageZoom = defineAsyncComponent(() => import("./_image/image-zoom/index.vue"));
const ImageFade = defineAsyncComponent(() => import("./_image/image-fade/index.vue"));
const ImageSwipe = defineAsyncComponent(() => import("./_image/image-swipe/index.vue"));
//import image text components
const ImageTextBasic = defineAsyncComponent(() => import("./_imagetext/image-text-basic/index.vue"));
const ImageTextEnlarge = defineAsyncComponent(() => import("./_imagetext/image-text-enlarge/index.vue"));
const ImageTextZoom = defineAsyncComponent(() => import("./_imagetext/image-text-zoom/index.vue"));
const ImageTextFade = defineAsyncComponent(() => import("./_imagetext/image-text-fade/index.vue"));
const ImageTextSwipe = defineAsyncComponent(() => import("./_imagetext/image-text-swipe/index.vue"));
//import property details components
const PropertyDetailsGallery1 = defineAsyncComponent(() => import("./_propertyDetails/gallery1/index.vue"));
const PropertyDetailsGallery2 = defineAsyncComponent(() => import("./_propertyDetails/gallery2/index.vue"));
const PropertyDetailsGalleryOverlay = defineAsyncComponent(() => import("./_propertyDetails/galleryOverlay/index.vue"));
const PropertyDetailsRte = defineAsyncComponent(() => import("./_propertyDetails/propertyDetailsRte/index.vue"));
const PropertyDetailsMap = defineAsyncComponent(() => import("./_propertyDetails/googleMaps/index.vue"));
const PropertyButtons = defineAsyncComponent(() => import("./_propertyDetails/propertyButtons/index.vue"));
const PropertyDetailsAgent = defineAsyncComponent(() => import("./_propertyDetails/Agents/index.vue"));
const PropertyDetailsDocuments = defineAsyncComponent(() => import("./_propertyDetails/documents/index.vue"));
const PropertyDetailsTable = defineAsyncComponent(() => import("./_propertyDetails/table/index.vue"));
const PropertyVideo = defineAsyncComponent(() => import("./_propertyDetails/video/index.vue"));
const StrucutredData = defineAsyncComponent(() => import("./_propertyDetails/structuredData/index.vue"));
const PropertyDetailsCurrencyExchange = defineAsyncComponent(() => import("./_propertyDetails/currencyExchange/index.vue"));
const PropertyDetailsIcons = defineAsyncComponent(() => import("./_propertyDetails/Icons/index.vue"));
//import footer components
const FooterNavigation = defineAsyncComponent(() => import("./_footer/navigation/index.vue"));
const FooterAddress = defineAsyncComponent(() => import("./_footer/address/index.vue"));
const FooterContact = defineAsyncComponent(() => import("./_footer/contact/index.vue"));
const FooterPoweredBySurga = defineAsyncComponent(() => import("./_footer/poweredBySurga/index.vue"));
const FooterSocialMedia = defineAsyncComponent(() => import("./_footer/socialMedia/index.vue"));
//import communication components
const ContactUsSimple = defineAsyncComponent(() => import("./_communication/contact-us/simple/index.vue"));
//import misc components
const Divider = defineAsyncComponent(() => import("./_misc/divider/index.vue"));
const Video = defineAsyncComponent(() => import("./_misc/video/index.vue"));
const Maps = defineAsyncComponent(() => import("./_misc/googleMaps/index.vue"));
const GalleryCustom = defineAsyncComponent(() => import("./_misc/galleryCustom/index.vue"));
//import overlay components
const WatchlistOverlay = defineAsyncComponent(() => import("./_overlays/watchlist/index.vue"));
const BackTopOverlay = defineAsyncComponent(() => import("./_overlays/backtotop/index.vue"));
const CookieOverlay = defineAsyncComponent(() => import("./_overlays/cookie/index.vue"));
//import news components
const NewsSearch = defineAsyncComponent(() => import("./_news/searchbar/index.vue"));
const NewsSimple = defineAsyncComponent(() => import("./_news/simple/index.vue"));
const NewsText = defineAsyncComponent(() => import("./_news/text/index.vue"));
const StructuredDataNews = defineAsyncComponent(() => import("./_news/structuredData/index.vue"));
//import agents components
const AgentsSimple = defineAsyncComponent(() => import("./_agents/simple/index.vue"));
const AgentsSearchbar = defineAsyncComponent(() => import("./_agents/searchbar/index.vue"));
// import agents search components
const AgentsSearchSimple = defineAsyncComponent(() => import("./_agents/search-simple/index.vue"));
//import watchlist modules
const WatchlistButtons = defineAsyncComponent(() => import("./watchlist/buttons/index.vue"));
// import location modules
const LocationText = defineAsyncComponent(() => import("./_location/text/index.vue"));
// import testimonials modules
const TestimonialsSimple = defineAsyncComponent(() => import("./_testimonials/simple/index.vue"));
// import map search module
const MapSearch = defineAsyncComponent(() => import("./_property/mapSearch/index.vue"));
// import microsite module
const Microsite = defineAsyncComponent(() => import("./_property/microsite/index.vue"));
// import number counter module
const NumberCounter = defineAsyncComponent(() => import("./numberCounter/index.vue"));
// import auto-scroll modules 
const AutoScrollArrow = defineAsyncComponent(() => import("./_autoScroll/downArrow/index.vue"));
// import grid javascript
import { setupGrid } from "../js/surgagrid";

//run grid setup javascript to setup site grid.
setupGrid();
//////////////////////////////Initiate App//////////////////////////////
var initNode = document.getElementsByTagName("initiate-app")[0];
mountVueComponent(initNode, Initiator, false);

////////////////////////////////NAVBARS/////////////////////////////////
mountVueComponents("navbar-basic-component", NavBasicDesktop);
mountVueComponents("navbar-indent-component", NavIndentDesktop);
mountVueComponents("navbar-basic-mobile-component", NavBasicMobile);
mountVueComponents("navbar-minimal-component", NavMinimalDesktop);
mountVueComponents("navbar-minimal-mobile-component", NavMinimalMobile);

///////////////////////////////CUSTOM COMPONENTS/////////////////////
mountVueComponents("text-component", Text);
mountVueComponents("rte-general-component", SurgaRte);
mountVueComponents("rte-script-component", SurgaSecureRte);
mountVueComponents("sort-retro-component", SortbarBasic);
mountVueComponents("search-component", SearchBasic);
mountVueComponents("search-box-component", SearchBox);
mountVueComponents("featured-property-component", FeaturedProperty);
// property search components
mountVueComponents("search-result-component", ListingResults);
mountVueComponents("watchlist-result-component", WatchlistResults);
mountVueComponents("property-result-component", AgencyResults);
mountVueComponents("agent-result-component", AgentResults);
mountVueComponents("auction-result-component", AuctionResults);
// image components
mountVueComponents("image-basic-component", ImageBasic);
mountVueComponents("image-enlarge-component", ImageEnlarge);
mountVueComponents("image-fade-component", ImageFade);
mountVueComponents("image-swipe-component", ImageSwipe);
mountVueComponents("image-zoom-component", ImageZoom);
// image text components
mountVueComponents("image-text-basic-component", ImageTextBasic);
mountVueComponents("image-text-enlarge-component", ImageTextEnlarge);
mountVueComponents("image-text-zoom-component", ImageTextZoom);
mountVueComponents("image-text-fade-component", ImageTextFade);
mountVueComponents("image-text-swipe-component", ImageTextSwipe);
// property details components
mountVueComponents("property-details-gallery1-component", PropertyDetailsGallery1);
mountVueComponents("property-details-gallery2-component", PropertyDetailsGallery2);
mountVueComponents("property-details-gallery-overlay", PropertyDetailsGalleryOverlay);
mountVueComponents("property-details-rte-component", PropertyDetailsRte);
mountVueComponents("property-details-map-component", PropertyDetailsMap);
mountVueComponents("property-buttons-component", PropertyButtons);
mountVueComponents("property-details-agents-component", PropertyDetailsAgent);
mountVueComponents("property-details-documents-component", PropertyDetailsDocuments);
mountVueComponents("property-table-component", PropertyDetailsTable);
mountVueComponents("property-video-component", PropertyVideo);
mountVueComponents("property-details-add-structured-data", StrucutredData);
mountVueComponents("property-details-currency-exchange-component", PropertyDetailsCurrencyExchange)
mountVueComponents("property-icons-component", PropertyDetailsIcons)
// footer components
mountVueComponents("footer-nav-component", FooterNavigation);
mountVueComponents("footer-address-component", FooterAddress);
mountVueComponents("footer-contact-component", FooterContact);
mountVueComponents("footer-powered-by-surga-component", FooterPoweredBySurga);
mountVueComponents("footer-social-media-component", FooterSocialMedia);
// communication components
mountVueComponents("contact-us-simple-component",ContactUsSimple);
// misc components
mountVueComponents("divider-component", Divider);
mountVueComponents("video-component", Video);
mountVueComponents("google-map-component", Maps);
mountVueComponents("gallery-custom-component", GalleryCustom);
// overlay components
mountVueComponents("watchlist-overlay-component", WatchlistOverlay);
mountVueComponents("back-top-overlay-component", BackTopOverlay);
mountVueComponents("cookie-overlay-component", CookieOverlay);
// news components
mountVueComponents("news-search-component", NewsSearch);
mountVueComponents("news-simple-component", NewsSimple);
mountVueComponents("news-text-component", NewsText);
mountVueComponents("news-structured-data-component", StructuredDataNews);
// agents components
mountVueComponents("agents-main-component", AgentsSimple);
// agents search components
mountVueComponents("agents-search-result-component", AgentsSearchSimple);
mountVueComponents("agents-search-component", AgentsSearchbar);
// watchlist components
mountVueComponents("watchlist-buttons-component", WatchlistButtons);
// location modules
mountVueComponents("location-text-component", LocationText);
// testimonials modules
mountVueComponents("testimonials-simple-component", TestimonialsSimple);
// import map search module
mountVueComponents("property-map-component", MapSearch);
// import microsite module
mountVueComponents("property-microsite-component", Microsite);
// import number counter module
mountVueComponents("number-counter-component", NumberCounter);
// import auto-scroll modules 
mountVueComponents("autoscroll-down-arrow-component", AutoScrollArrow);

//////////////////////////////Post Load Operations///////////////////////////
let postNode = document.getElementsByTagName("post-load")[0];
postNode.setAttribute('data-async-module-num', asyncModuleNumber);
mountVueComponent(postNode, PostLoad, false);

// mount multiple async components.
function mountVueComponents(htmlTag, component, isAsync = true) {
  //Retrieve all instances of component.
  var nodes = document.getElementsByTagName(htmlTag);
  //Iterate through all instances
  for (var i = 0; i < nodes.length; ++i) {
    mountVueComponent(nodes[i], component, isAsync);
  }
}

// mount a single sync component.
function mountVueComponent(node, componentName, isAsync = true) {
  let appSettings;
  if (isAsync) {
    asyncModuleNumber++;
    appSettings = getAppSettingsAsync(componentName);
  } else {
    appSettings = getAppSettings(componentName);
  }
  createApp(appSettings).mount(node);
}

// construct settings for async modules.
function getAppSettingsAsync(asyncComponent) {
  return {
    components: {
      asyncComponent
    },
    render() {
      return (h(asyncComponent))
    }
  }
}

// construct settings for modules.
function getAppSettings(component) {
  return {
    render() {
      return h(component);
    }
  }
}
